import {ApplicationRef, Inject, Injectable, NgZone, PLATFORM_ID} from '@angular/core';
import {first, ReplaySubject} from 'rxjs';
import {WINDOW} from './window.service';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {SessionService} from './session.service';
import {User} from '../models/user';
import {platformBrowser} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  public static chatStatusChanged: ReplaySubject<string> = new ReplaySubject(1);
  private currentChatState;
  private initializationStarted = false;

  constructor(
    private ngZone: NgZone,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private applicationRef: ApplicationRef) {

    // mark agents as unavailable/available
    if (isPlatformBrowser(this.platformId) && !this.window['jivo_onLoadCallback']) {

      // load chat script after a delay
      applicationRef.isStable.pipe( first((isStable) => isStable) ).subscribe(() => {
        setTimeout( () => { this.initialize(); }, 5000);
      });

      this.window['jivo_onLoadCallback'] = () => {
        this.toggleVisibility('hidden');

        // current limitation, does not update chat mode value so no reason to run it over and over
        if (this.window['jivo_api']) {
          const nextChatState = this.window['jivo_api'].chatMode();
          if (nextChatState !== this.currentChatState) {
            this.currentChatState = nextChatState;
            this.ngZone.run(() => {
              ChatService.chatStatusChanged.next(nextChatState === 'online' ? 'AVAILABLE' : 'UNAVAILABLE');
            });
          }
        }
      };
    }

    // mark agents as unavailable/available
    if (isPlatformBrowser(this.platformId) && !this.window['jivo_onClose']) {
      this.window['jivo_onClose'] = () => {
        this.toggleVisibility('hidden');
      };
    }

    SessionService.currentUserChanged.subscribe((currentUser:User) => {
      this.identify({
        email: currentUser?.email,
        name: currentUser?.fullname,
      });
    });
  }

  public initialize(): void {
    if (isPlatformBrowser(this.platformId) && !this.initializationStarted) {
      this.initializationStarted = true;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '//code.jivosite.com/widget/7cVwDBQlWN';
      script.defer = true;
      document.head.appendChild(script);

      if (isPlatformBrowser(this.platformId)) {
        this.applicationRef.isStable.pipe( first((isStable) => isStable) ).subscribe(() => {
          setTimeout( () => {
            if (!this.window['jivo_api']) {
              this.ngZone.run(() => {
                ChatService.chatStatusChanged.next('LOAD_FAILED');
              });
            }
          }, 2000);
        });
      }
    }
  }

  private toggleVisibility(state: string): void {
    const jdivs = this.document.getElementsByTagName('jdiv');
    if (jdivs[0]) {
      jdivs[0].className = state == 'hidden' ? 'hide-chat' : 'show-chat';
    }
  }

  private identify(visitorInfo = null) {
    if (isPlatformBrowser(this.platformId) && this.window['jivo_api']) {
      this.window['jivo_api'].setContactInfo(visitorInfo);
    }
  }

  public track(event, data) {
    if (isPlatformBrowser(this.platformId) && this.window['jivo_api']) {
      this.window['jivo_api'].setCustomData([{
        title: event,
        content: data,
        link: 'https://www.matboardandmore.com/something/design'
      }]);
    }
  }

  public startChat() {
    if (isPlatformBrowser(this.platformId) && this.window['jivo_api']) {
      this.toggleVisibility('visible');
      this.window['jivo_api'].open();
    }
  }

  public async getRecordInfo(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.window['jivo_api'].getVisitorNumber(function(error, visitorNumber) {
        if (error) {
          reject(error);
        }
        resolve({
          recordingId: visitorNumber
        });
      });
    });
  }
}
