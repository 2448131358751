import {Component, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../shared/services/notification.service';
import {ChatService} from '../shared/services/chat.service';
import {ContactUsService} from '../shared/services/contact-us.service';
import {UntypedFormControl} from '@angular/forms';
import {CmsService} from '../shared/services/cms.service';

@Component({
  selector: 'mbnm-contact-us-dialog',
  templateUrl: './contact-us-dialog.component.html',
  styleUrls: ['./contact-us-dialog.component.scss']
})
export class ContactUsDialogComponent implements OnInit {

  public dynamicTextBlocks;
  public message: any = {};
  public sending = false;
  public chatLoadFailed = false;

  constructor(
    private chatService: ChatService,
    private cmsService: CmsService,
    private contactUsService: ContactUsService,
    private router: Router,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<ContactUsDialogComponent>
  ) {}

  @ViewChild('email') email: UntypedFormControl;
  ngOnInit() {
    this
      .cmsService
      .getDynamicTextBlocks()
      .then( results => {
        this.dynamicTextBlocks = results;
      });
  }

  async sendMessage() {
    if (!this.message.Address || !this.message.Name || !this.message.Message) {
      this.notificationService.createNotification('Please fill out all required fields.', 'error');
    } else if (this.email && this.email.errors && this.email.errors.email) {
      this.notificationService.createNotification('Please enter a valid email address.', 'error');
    } else {
      this.sending = true;
      this.message.Subject = 'Away Message';
      const recordingInfo = await this.chatService.getRecordInfo();
      if (recordingInfo !== null) {
        this.message.LOLiveRecordingId = recordingInfo.recordingId;
        this.message.LOSiteId = recordingInfo.siteId;
      }

      this.contactUsService
        .sendMessage(this.message)
        .subscribe( (result: any) => {
          if (result === true) {
            this.notificationService.createNotification('Thank you for contact us! Someone will be in touch shortly.', 'success');
            this.dialogRef.close();
          }
        }, () => {
          this.notificationService.createNotification('Something went wrong sending your message. Please try again.', 'error');
          this.dialogRef.close();
        });
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
