<div mat-dialog-title class="fx-layout-row fx-align--x-center">
  <h2 *ngIf="chatLoadFailed">
    Live Chat Failed to Load!
  </h2>
  <h2 *ngIf="!chatLoadFailed">
    Sorry We Missed You!
  </h2>
  <div class="fx-flex-grow"></div>
  <button mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon svgIcon="x"> </mat-icon>
  </button>
</div>

<mat-dialog-content>
  <p *ngIf="chatLoadFailed">
    Chat can fail to load if you have an ad blocker or antivirus that
    is mistaking our chat bot for something malicious. If you do, please disable it and try again.
  </p>
  <p *ngIf="chatLoadFailed">
    Meanwhile, you can use the email form below to contact us.
  </p>
  <p *ngIf="!chatLoadFailed">
    We are available Monday to Friday, {{dynamicTextBlocks?.hours}} EST.
    Sorry we weren't available for chat but we will get back to your e-mail right away.
  </p>
  <form (submit)="sendMessage()">
    <mat-form-field >
      <mat-label>your name</mat-label>
      <input matInput name="Name" type="text" required="true" [(ngModel)]="message.Name">
    </mat-form-field>
    <mat-form-field >
      <mat-label>your email</mat-label>
      <input matInput name="Address" type="email" required="true" [email]="true" [(ngModel)]="message.Address" #email="ngModel">
    </mat-form-field>
    <mat-form-field  class="message">
      <mat-label>message</mat-label>
      <textarea matInput name="Message" required="true" [(ngModel)]="message.Message"></textarea>
    </mat-form-field>
    <ng-container *ngIf="message.DesignReference">
      <strong>Note:</strong>
      A link to your design will automatically be included with your message
    </ng-container>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="fx-layout-row fx-align--space-between-x">
  <button mat-flat-button color="primary" (click)="sendMessage()" [disabled]="sending">
    Send Message
  </button>
  <button (click)="cancel()" mat-flat-button color="secondary">Cancel</button>
</mat-dialog-actions>
